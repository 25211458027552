<template>
<!-- style="height: 25px; width: 100%;" -->
  <div ref="editor" class="v-input"></div>
</template>

<script>
export default {
  name: 'BoolEditor',
  components: {
  },
  props: {
    value: {
      type: [Object, null],
      required: true
    },
    minLine: {
      type: Number,
      required: false,
      default: 1
    },
    maxLine: {
      type: Number,
      required: false,
      default: 1
    }
  },
  data: () => ({
    editor: null
  }),
  methods: {
    jinja_template: function (str, i) {
      let ch = str.charAt(i)
      let ii = -1
      i += 1
      if (str.charAt(i) === '{') {
        i += 1
        ch = str.charAt(i)
        while (ch) {
          if (ch === '{') {
            ii = this.jinja_template(str, i)
            if (ii > -1) {
              i = ii
            }
          } else if (ch === '}') {
            if (str.charAt(i + 1) === '}') {
              return i + 1
            }
          }
          i += 1
          ch = str.charAt(i)
        }
        return -1
      } else {
        return -1
      }
    },
    cleanTemplate: function (str) {
      let i = 0
      let ii = -1
      let ch = str.charAt(i)
      let clean = ''
      while (ch) {
        if (ch === '{') {
          ii = this.jinja_template(str, i)
          if (ii > -1) {
            i = ii
            clean += '0'
          } else {
            clean += ch
          }
        } else {
          clean += ch
        }
        i += 1
        ch = str.charAt(i)
      }
      return clean
    }
  },
  computed: {},
  mounted () {
    this.editor = window.ace.edit(this.$refs.editor)
    this.editor.setTheme('ace/theme/github')
    this.editor.session.setMode('ace/mode/jinja_json')
    this.editor.session.setTabSize(2)
    this.editor.setOptions({
      minLines: this.minLine,
      maxLines: this.maxLine
    })
    if (this.value && this.value.BOOL) {
      this.editor.setValue(this.value.BOOL)
      this.editor.focus()
      this.editor.navigateFileEnd()
    } else {
      this.editor.setValue('')
    }
    let vm = this
    this.editor.session.on('tokenizerUpdate', function (o) {
      let val = vm.editor.getValue()
      console.log(val)
      try {
        let annotations = vm.editor.session.getAnnotations().filter((a) => a.type === 'error')
        if (annotations.length === 0) {
          if (val) {
            let js = JSON.parse(vm.cleanTemplate(val))
            if (typeof js === 'number') {
              console.log({ BOOL: val })
              vm.$emit('input', { BOOL: val })
            }
          }
        } else {
          vm.$emit('error', annotations)
        }
      } catch (e) {
        vm.$emit('error', e)
      }
    })
  }
}
</script>
